html,
body {
  background-color: white;
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

p {
  margin: 0
}

* {
  box-sizing: border-box;
}

input[type=text]:focus {
  outline: none;      /* Remove default outline and use border or box-shadow */
  box-shadow: none; /* Full freedom. (works also with border-radius) */
}

input[type=password]:focus {
  outline: none;      /* Remove default outline and use border or box-shadow */
  box-shadow: none; /* Full freedom. (works also with border-radius) */
}

input[type=number]:focus {
  outline: none;      /* Remove default outline and use border or box-shadow */
  box-shadow: none; /* Full freedom. (works also with border-radius) */
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance:textfield;
}

.loading-page {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh
}

.loading-image {
  width: 250px
}

#product-name {
  width: 50%;
}

#product-price {
  width: 50%;
  text-align: right;
}

.modal {
  z-index: 98;
  background: #14151A;
  mix-blend-mode: normal;
  opacity: 0.5;
  position: fixed;
  width: 100%;
  height: 100%
}

.modal-layer {
  z-index: 100;
  background: #14151A;
  mix-blend-mode: normal;
  opacity: 0.5;
  position: fixed;
  width: 100%;
  height: 100%
}

.modal-content {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 99;
  background: white;
  width: 100%;
  height: 60%;
  padding-bottom: 80px;
  border-radius: 12px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.modal-header {
  z-index: 100;
  position: fixed;
  background-color: white;
  width: 100%;
  height: 64px;
  border-radius: 12px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.modal-header-content {
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  position: relative;
}

.modal-header-content-voucher {
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  padding: 0px 16px;
  position: relative;
  align-items: center;
}

.modal-body {
  padding-top: 50px;
}

.modal-body-info {
  padding-top: 60px;
}

.modal-content-info {
  position: fixed;
  overflow: scroll;
  left: 0;
  bottom: 0;
  z-index: 99;
  background: white;
  width: 100%;
  max-height: 50%;
  min-height: 10%;
  padding-bottom: 80px;
  border-radius: 12px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.modal-content-cancel {
  position: fixed;
  overflow: scroll;
  left: 0;
  bottom: 0;
  z-index: 99;
  background: white;
  width: 100%;
  max-height: 50%;
  height: 30%;
  min-height: 20%;
  padding-bottom: 80px;
  border-radius: 12px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.modal-content-confirm {
  position: fixed;
  overflow: scroll;
  left: 0;
  bottom: 0;
  z-index: 99;
  background: white;
  width: 100%;
  max-height: 50%;
  /* height: 30%; */
  min-height: 20%;
  padding-bottom: 80px;
  border-radius: 12px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.modal-content-voucher {
  position: fixed;
  overflow: scroll;
  left: 0;
  bottom: 0;
  z-index: 99;
  background: white;
  width: 100%;
  height: 60vh;
  border-radius: 12px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.modal-body-content {
  padding: 10px 16px;
  font-size: 14px;
}

/* Customize the label (the container) */
.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  padding-left: 16px;
  height: 56px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-bottom: 1px solid #E8EAEF;
}

/* Hide the browser's default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.checkmark {
  position: relative;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #F5B22D;
}

/* When the radio button is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #364058;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container .checkmark:after {
  top: 32%;
  left: 32%;
  width: 7.5px;
  height: 7.5px;
  border-radius: 50%;
  background: #F5B22D;
}

.radio-text {
  font-size: 16px;
  margin: 0;
  margin-left: 10px;
}

.accordion {
  margin-top: 40px
}

.accordion-item {
  display: flex;
  flex-direction: column;
  min-height: 52px;
  padding: 0px 0px;
}

.accordion-item:nth-child(1) .accordion-title {
  border-top:none;
}

.accordion-title {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 52px;
  align-items: center;
  /* border-bottom: 1px solid #F1F2F5; */
  border-top: 2px solid #F1F2F5;
}

.accordion-content {
  padding-bottom: 14px;
  font-size: 12px;
  white-space: pre-line;
}

ul { 
  padding:0;
  margin: 0;
} 

.timeline {
  list-style-type: none;
  width: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.li {
  max-width: 25vh;
  transition: all 200ms ease-in;
}

.status {
  padding: 0px 10px;
  height: 50px;
  max-height: 50px;
  width: 28vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 2px solid #D6DCE0;
  position: relative;
  transition: all 200ms ease-in;
}

.status p {
  margin-top: 15px;
  font-size: 10px;
  font-weight: 600;
  /* line-height: 4; */
}
.status:before {
  content: "";
  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 20px;
  border: 1px solid #ddd;
  position: absolute;
  top: -12px;
  left: 42%;
  transition: all 200ms ease-in;
}

.li.complete .status {
  border-top: 2px solid #F7B720;
}
.li.complete .status:before {
  background-color: #F7B720;
  border: none;
  transition: all 200ms ease-in;
}
.li.complete .status p {
  color: #F7B720;
}


#snackbar {
  visibility: hidden; /* Hidden by default. Visible on click */
  min-width: 180px; /* Set a default minimum width */
  margin-left: -90px; /* Divide value of min-width by 2 */
  height: 40px;
  background-color: #F7B720; /* Black background color */
  font-size: 14px;
  color: #232C44; /* White text color */
  text-align: center; /* Centered text */
  border: none;
  border-radius: 100px; /* Rounded borders */
  padding: 9px; /* Padding */
  position: fixed; /* Sit on top of the screen */
  z-index: 100; /* Add a z-index if needed */
  left: 50%; /* Center the snackbar */
  bottom: 100px; /* 30px from the bottom */
}

/* Show the snackbar when clicking on a button (class added with JavaScript) */
#snackbar.show {
  visibility: visible; /* Show the snackbar */
  /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
  However, delay the fade out process for 2.5 seconds */
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

#snackbar-payment {
  visibility: hidden; /* Hidden by default. Visible on click */
  min-width: 180px; /* Set a default minimum width */
  margin-left: -90px; /* Divide value of min-width by 2 */
  height: 40px;
  background-color: #F7B720; /* Black background color */
  font-size: 14px;
  color: #232C44; /* White text color */
  text-align: center; /* Centered text */
  border: none;
  border-radius: 100px; /* Rounded borders */
  padding: 9px; /* Padding */
  position: fixed; /* Sit on top of the screen */
  z-index: 100; /* Add a z-index if needed */
  left: 50%; /* Center the snackbar */
  bottom: 100px; /* 30px from the bottom */
}

/* Show the snackbar when clicking on a button (class added with JavaScript) */
#snackbar-payment.show {
  visibility: visible; /* Show the snackbar */
  /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
  However, delay the fade out process for 2.5 seconds */
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

#snackbar-voucher {
  visibility: hidden; /* Hidden by default. Visible on click */
  min-width: 180px; /* Set a default minimum width */
  margin-left: -90px; /* Divide value of min-width by 2 */
  height: 40px;
  background-color: #F7B720; /* Black background color */
  font-size: 14px;
  color: #232C44; /* White text color */
  text-align: center; /* Centered text */
  border: none;
  border-radius: 100px; /* Rounded borders */
  padding: 9px; /* Padding */
  position: fixed; /* Sit on top of the screen */
  z-index: 100; /* Add a z-index if needed */
  left: 50%; /* Center the snackbar */
  bottom: 100px; /* 30px from the bottom */
}

/* Show the snackbar when clicking on a button (class added with JavaScript) */
#snackbar-voucher.show {
  visibility: visible; /* Show the snackbar */
  /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
  However, delay the fade out process for 2.5 seconds */
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

.embla {
  overflow: hidden;
}
.embla__container {
  display: flex;
}
.embla__slide {
  color: white;
  position: relative;
  flex: 0 0 100%;
  padding: 10Px 16px;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: flex-start;
}

.embla__slide__text {
  font-size: 14px;
  margin-left: 12px;
}

.embla__dots {
  display: flex;
  justify-content: left;
  position: relative;
  margin-right: auto;
  padding-left: 43px;
  padding-bottom: 14px;
}

.embla__dot {
  background-color: transparent;
  cursor: pointer;
  position: relative;
  padding: 0;
  outline: 0;
  border: 0;
  width: 20px;
  height: 5px;
  margin-right: 0px;
  margin-left: 0px;
  display: flex;
  align-items: left;
  justify-content: flex-start;
}

.embla__dot:after {
  background-color: #CBCBCB;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  content: "";
}

.embla__dot.is-selected:after {
  background-color: #F5B22D;
  opacity: 1;
}

.modal-login {
  position: fixed;
  overflow: scroll;
  left: 0;
  bottom: 0;
  z-index: 99;
  background: white;
  width: 100%;
  overflow: auto;
  padding: 20px 16px;
  /* padding-bottom: 80px; */
  border-radius: 12px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 100px; opacity: 1;}
}

@keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 100px; opacity: 1;}
}

@-webkit-keyframes fadeout {
  from {bottom: 100px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}

@keyframes fadeout {
  from {bottom: 100px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}

@media (min-width: 300px) {
  #product-name {
    width: 42vh;
  }

  #product-price {
    width: 55vh;
    text-align: right;
  }
}


@media (min-width: 360px) {
  #product-name {
    width: 45vh;
  }

  #product-price {
    width: 50vh;
    text-align: right;
  }
}

@media (min-width: 400px) {
  #product-name {
    width: 55vh;
  }

  #product-price {
    width: 45vh;
    text-align: right;
  }
}

@media (min-width: 768px) {
  #product-name {
    width: 70vh;
  }
  
  #product-price {
    width: 30vh;
    text-align: right;
  }
}
@media (min-width: 1024px) {}
@media (min-width: 1220px) {}
@media (min-width: 1440px) {}